@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee !important;
  background-size: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.dribble {
  margin-top: 1.2em;
  width: 50px;
  height: 50px;
  filter: invert(29%) sepia(88%) saturate(1108%) hue-rotate(181deg) brightness(104%) contrast(85%);
  margin-left: 6%;
}

.dribble:hover {
  cursor: pointer;
  filter: invert(35%) sepia(10%) saturate(2758%) hue-rotate(234deg) brightness(101%) contrast(88%);
}

.joberty {
  margin-top: 1.2em;
  width: 50px;
  height: 40px;
  filter: invert(29%) sepia(88%) saturate(1108%) hue-rotate(181deg) brightness(104%) contrast(85%);
}

.joberty:hover {
  cursor: pointer;
  filter: invert(35%) sepia(10%) saturate(2758%) hue-rotate(234deg) brightness(101%) contrast(88%);
}